.container {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.button {
	width: 100%;
	position: relative;
	background: rgba(255, 255, 255, 0.1);
	transition: background 0.2s;
	padding: var(--space-2) var(--space-3);
}

.button:hover {
	background: rgba(255, 255, 255, 0.5);
}

.confirming {
	background: rgba(255, 0, 0, 0.5);
}

.confirming:hover {
	background: rgba(255, 0, 0, 0.8);
}

.userInfoHover {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%) translateY(-10px);
	background: var(--background);
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-sm);
	padding: 8px;
	margin-top: 4px;
	white-space: nowrap;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out,
		visibility 0.2s ease-in-out;
	gap: 4px;
}

.userInfoHover span {
	display: block;
	text-align: center;
}

.userInfoHover span:last-child {
	font-size: 0.9em;
	color: var(--gray-600);
}

.button:hover .userInfoHover {
	visibility: visible;
	opacity: 1;
	transform: translateX(-50%) translateY(0);
}
