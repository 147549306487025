.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	z-index: 0;
}

.layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.favicon {
	position: absolute;
	animation-name: moveUp;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	will-change: transform;
	/* Reduce blur effect and make it more proportional to depth */
	filter: blur(calc((3 - var(--depth)) * 0.3px));
	transform-origin: center;
	transform: rotate(var(--icon-angle, 0deg)); /* Apply icon rotation */
}

.favicon.static {
	animation: none;
	transform: rotate(var(--icon-angle, 0deg));
}

@keyframes moveUp {
	0% {
		transform: rotate(var(--movement-angle, 0deg)) translateY(100vh)
			rotate(var(--icon-angle, 0deg));
		filter: blur(calc((3 - var(--depth)) * 0.3px));
	}

	100% {
		transform: rotate(var(--movement-angle, 0deg)) translateY(-120vh)
			rotate(var(--icon-angle, 0deg));
		filter: blur(calc((3 - var(--depth)) * 0.3px + 2px));
	}
}

.directionToggle {
	opacity: 10%;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	pointer-events: auto;
	padding: 8px 16px;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2);
	color: white;
	border: 1px solid rgba(255, 255, 255, 0.3);
	cursor: pointer;
}

.directionToggle:hover {
	opacity: 100%;
	background: rgba(255, 255, 255, 0.3);
}
